import PropTypes from "prop-types";

export const beneficialOwnerContactRelationships = {
	DIRECTOR: "DIRECTOR",
	SHAREHOLDING_VOTING_RIGHTS_GREATER_THAN_25_PERCENT:
		"SHAREHOLDING_VOTING_RIGHTS_GREATER_THAN_25_PERCENT",
	GENERAL_PARTNER: "GENERAL_PARTNER",
	PARTNER: "PARTNER",
	LIMITED_PARTNER_GREATER_THAN_25_PERCENT:
		"LIMITED_PARTNER_GREATER_THAN_25_PERCENT",
	BENEFICIARY_GREATER_THAN_25_PERCENT: "BENEFICIARY_GREATER_THAN_25_PERCENT",
	BEARER_AND_NOMINEE_SHAREHOLDER: "BEARER_AND_NOMINEE_SHAREHOLDER",
	TRUSTEE: "TRUSTEE",
	NON_DISCRETIONARY_BENEFICIARY: "NON_DISCRETIONARY_BENEFICIARY",
	DISCRETIONARY_BENEFICIARY: "DISCRETIONARY_BENEFICIARY",
	SETTLOR: "SETTLOR",
	APPOINTOR_OR_PROTECTOR: "APPOINTOR_OR_PROTECTOR",
	NOMINEE_SHAREHOLDER: "NOMINEE_SHAREHOLDER",
	OTHER_AUTHORISED_PARTY: "OTHER_AUTHORISED_PARTY",
	EXECUTOR_OR_ADMINISTRATOR: "EXECUTOR_OR_ADMINISTRATOR",
};

export const beneficialOwnerContactRelationshipLabels = {
	[beneficialOwnerContactRelationships.DIRECTOR]: "Director",
	[beneficialOwnerContactRelationships.SHAREHOLDING_VOTING_RIGHTS_GREATER_THAN_25_PERCENT]:
		"Shareholding / voting rights > 25%",
	[beneficialOwnerContactRelationships.GENERAL_PARTNER]: "General partner",
	[beneficialOwnerContactRelationships.PARTNER]: "Partner",
	[beneficialOwnerContactRelationships.LIMITED_PARTNER_GREATER_THAN_25_PERCENT]:
		"Limited partner > 25% shareholding / voting",
	[beneficialOwnerContactRelationships.BENEFICIARY_GREATER_THAN_25_PERCENT]:
		"Beneficiary > 25% shareholding / voting",
	[beneficialOwnerContactRelationships.BEARER_AND_NOMINEE_SHAREHOLDER]:
		"Bearer & Nominee Shareholder",
	[beneficialOwnerContactRelationships.TRUSTEE]: "Trustee",
	[beneficialOwnerContactRelationships.NON_DISCRETIONARY_BENEFICIARY]:
		"Non-Discretionary Beneficiary",
	[beneficialOwnerContactRelationships.DISCRETIONARY_BENEFICIARY]:
		"Discretionary Beneficiary",
	[beneficialOwnerContactRelationships.SETTLOR]: "Settlor",
	[beneficialOwnerContactRelationships.APPOINTOR_OR_PROTECTOR]:
		"Appointor or Protector",
	[beneficialOwnerContactRelationships.NOMINEE_SHAREHOLDER]:
		"Nominee Shareholder",
	[beneficialOwnerContactRelationships.OTHER_AUTHORISED_PARTY]:
		"Other authorised party",
	[beneficialOwnerContactRelationships.EXECUTOR_OR_ADMINISTRATOR]:
		"Executor or Administrator",
};

export const beneficialOwnerContactRelationshipPropType = PropTypes.oneOf(
	Object.values(beneficialOwnerContactRelationships),
);
